import { getEnvVars } from "selectors/envVars";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Utils from "utils/Utils";

const useEnvVars = () => {
  const history = useHistory();

  const envVars = useSelector(getEnvVars);

  const setEnvVars = useCallback((newValues, state) => {
    const prevEnvVars = Utils.parseQueryString(window.location.search);

    history.push(`?${Utils.objectToQueryString({ ...prevEnvVars, ...newValues })}`, state);
  }, [history]);

  return [envVars, setEnvVars];
};

export default useEnvVars;
