import Css from "./style.module.scss";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React from "react";
import usePageData from "hooks/usePageData";

const Filters = () => {
  const { uiTexts } = useSelector(getTextsData);

  const { titleLangId } = usePageData();

  return (
    <>
      <div className={Css.filters}>
        <div className={Css.container}>
          <div className={Css.title}>
            <span>{uiTexts[titleLangId]}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Filters);
