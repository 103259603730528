import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import fallbackLogo from "assets/creditCard.svg";

import * as Icons from "@phosphor-icons/react";
import { Modal, Preloader } from "nlib/ui";
import { checkInstitutionsFetching, getInstitutionLinksData, getInstitutionsData } from "selectors/institutions";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import ImageWithFallback from "nlib/common/ImageWithFallback";
import InstitutionLinkStatuses from "const/InstitutionLinkStatuses";
import React, { useCallback } from "react";
import classNames from "classnames";
import moment from "moment";

const { ACCOUNT_EXPIRATION_DAYS_WARNING } = Constants;

const Empty = () => null;

const ConnectInstitutionWindow = ({ onClose }) => {
  const { uiTexts } = useSelector(getTextsData);

  const fetchingData = useSelector(checkInstitutionsFetching);

  const institutionsData = useSelector(getInstitutionsData);

  const institutionLinksData = useSelector(getInstitutionLinksData);

  const handleItemClick = useCallback(({ currentTarget: { dataset: { code } } }) => {
    onClose(code);
  }, [onClose]);

  return (
    <Modal
      className={Css.connectInstitutionWindow}
      dialogClassName={Css.dialogClassName}
      contentClassName={Css.contentClassName}
      title={uiTexts.connectBankAccounts}
      iconComponent={Icons.CreditCard}
      onClose={onClose}
      footerComponent={Empty}>
      {(!institutionsData.length || fetchingData)
        ? <Preloader />
        : (
          <div className={Css.list}>
            {institutionsData.map(({ id, code, logo, name }) => {
              const {
                accounts = [],
                status,
                expiresAt
              } = institutionLinksData.find(({ institutionId }) => id === institutionId) || {};

              const expired = moment.utc(expiresAt).isSameOrBefore(moment.utc());

              const disconnected = status !== InstitutionLinkStatuses.ONLINE;

              const warning = !expired && !disconnected
                && moment.duration(moment.utc(expiresAt).diff(moment.utc())).as("days") < ACCOUNT_EXPIRATION_DAYS_WARNING;

              const expiresAtText = moment.duration(moment.utc(expiresAt).diff(moment.utc())).humanize(true);

              return (
                <div key={code} className={Css.item}>
                  <div
                    key={code}
                    className={classNames(Css.content, !!accounts.length && Css.connected)}
                    data-code={code}
                    title={name}
                    onClick={handleItemClick}>
                    <div className={Css.logo}>
                      <ImageWithFallback src={logo} alt={name} fallback={fallbackLogo} />
                    </div>
                    <div className={Css.name}>{name}</div>
                    {expired || disconnected
                      ? <Icons.WarningCircle
                        title={expired ? uiTexts.expired : uiTexts.disconnected}
                        className={CommonCss.negativeText} />
                      : (warning
                        ? <Icons.WarningCircle title={`${uiTexts.expires}: ${expiresAtText}`} className={CommonCss.warningText} />
                        : <Icons.CheckCircle title={uiTexts.connected} />)}
                  </div>
                </div>
              );
            })}
          </div>
        )}
    </Modal>
  );
};

export default ConnectInstitutionWindow;
