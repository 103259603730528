import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import fallbackLogo from "assets/creditCard.svg";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Constants from "const/Constants";
import ImageWithFallback from "nlib/common/ImageWithFallback";
import InstitutionLinkStatuses from "const/InstitutionLinkStatuses";
import InstitutionsActions from "actions/InstitutionsActions";
import React, { useCallback, useEffect, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useShowCommonModal from "hooks/useShowCommonModal";

const { ACCOUNT_EXPIRATION_DAYS_WARNING } = Constants;

const StatusText = ({ expiresAt, expired, disconnected, expiresSoon }) => {
  const { uiTexts } = useSelector(getTextsData);

  if (expired) {
    return <span className={classNames(Css.text, Css.expired)}>{uiTexts.expired}</span>;
  }
  if (disconnected) {
    return <span className={classNames(Css.text, Css.expired)}>{uiTexts.disconnected}</span>;
  }

  return (
    <>
      <span className={classNames(Css.text, expiresSoon && Css.warning)}>
        {`${uiTexts.expires}: ${moment.duration(moment.utc(expiresAt).diff(moment.utc())).humanize(true)}`}
      </span>
      {!expiresSoon && (
        <span className={Css.successIcon}>
          <Icons.CheckCircle />
        </span>
      )}
    </>
  );
};

const Institution = ({ disabled, linkData }) => {
  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const { institution, accounts, status, expiresAt } = linkData;

  const expired = moment.utc(expiresAt).isSameOrBefore(moment.utc());

  const disconnected = status !== InstitutionLinkStatuses.ONLINE;

  const { code: institutionCode } = institution;

  const { uiTexts, messages } = useSelector(getTextsData);

  const [scrolled, setScrolled] = useState(false);

  const handleScroll = useCallback(() => {
    setScrolled(!!window.scrollY);
  }, []);

  const expiresSoon = !expired && !disconnected
    && moment.duration(moment.utc(expiresAt).diff(moment.utc())).asDays() < ACCOUNT_EXPIRATION_DAYS_WARNING;

  const handleReconnectAccount = useCallback(async() => {
    const result = await showCommonModal({
      text: messages.actionConfirm,
      confirm: true
    });

    if (result) {
      const link = await dispatch(InstitutionsActions.getConnectingLink(institutionCode));

      if (link) window.location = link;
    }
  }, [institutionCode, dispatch, messages, showCommonModal]);

  const handleTableAccountDelete = useCallback(async(event) => {
    event.stopPropagation();

    const { currentTarget: { dataset: { id } } } = event;

    const result = await showCommonModal({
      text: messages.actionConfirm,
      confirm: true
    });

    if (result) {
      await dispatch(InstitutionsActions.deleteInstitutionAccount(id));
    }
  }, [dispatch, messages.actionConfirm, showCommonModal]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <div className={classNames(Css.title, scrolled && Css.shadow)}>
        <div className={Css.logo}>
          <ImageWithFallback src={institution.logo} alt={institution.name} fallback={fallbackLogo} />
        </div>
        <div className={Css.name}>{institution.name}</div>
        <div className={Css.expiresAt}>
          <StatusText
            expiresAt={expiresAt}
            expiresSoon={expiresSoon}
            expired={expired}
            disconnected={disconnected} />
          {(expiresSoon || expired || disconnected) && (
            <Button
              danger={expired || disconnected}
              warning={!expired && !disconnected}
              title={expired ? uiTexts.reconnect : uiTexts.renew}
              disabled={disabled}
              className={Css.reconnectButton}
              onClick={handleReconnectAccount}>
              <Icons.ArrowsClockwise />
            </Button>
          )}
        </div>
      </div>
      <div className={Css.list}>
        {accounts.map((accountData) => {
          const {
            id: accountId,
            name,
            balance,
            currency,
            lastUpdatedAt,
            lastSyncAt,
            transactionsCount
          } = accountData;

          const balanceText = Utils.toMoneyString(balance || 0, currency);

          return (
            <div key={accountId} className={Css.account}>
              <div className={Css.header}>
                <div className={Css.name}>{name}</div>
                <div
                  data-negative={balance < 0 ? "" : undefined}
                  className={classNames(Css.balance, balance && CommonCss.coloredNumber)}>
                  {balanceText}
                </div>
              </div>
              <div className={Css.content}>
                <div className={Css.item}>
                  <div className={Css.label}>{uiTexts.transactions}</div>
                  <div>{transactionsCount}</div>
                </div>
                <div className={Css.item}>
                  <div className={Css.label}>{uiTexts.lastTransaction}</div>
                  <div>
                    {lastUpdatedAt
                      ? moment.utc(lastUpdatedAt).format(Constants.DATETIME_FORMATS.DATE_TEXT)
                      : Constants.EMPTY_PLACEHOLDER}
                  </div>
                </div>
                <div className={Css.item}>
                  <div className={Css.label}>{uiTexts.lastSync}</div>
                  <div>
                    {lastSyncAt
                      ? moment.duration(moment.utc(lastSyncAt).diff(moment.utc())).humanize(true)
                      : (
                        <>
                          <Icons.Spinner data-wait />
                          <span>{uiTexts.inProgress}</span>
                        </>
                      )}
                  </div>
                </div>
                <Button
                  outline danger
                  title={uiTexts.delete}
                  disabled={disabled}
                  className={Css.deleteButton}
                  data-id={accountId}
                  onClick={handleTableAccountDelete}>
                  <Icons.Trash />
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default React.memo(Institution);
