import React, { useCallback, useState } from "react";

import fallbackImage from "assets/fallback.svg";

const ImageWithFallback = ({ src, onError, fallback = fallbackImage, ...restProps }) => {
  const [loadingFail, setLoadingFail] = useState(false);

  const handleImageError = useCallback((...rest) => {
    setLoadingFail(true);
    if (onError) onError(...rest);
  }, [onError]);

  return <img src={loadingFail ? fallback : src} onError={handleImageError} {...restProps} />;
};

export default React.memo(ImageWithFallback);
